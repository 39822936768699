<template>
  <page-content>
    <common-table
      ref="table"
      path="web/iot/device/control-log"
      search-placeholder="搜索消息内容"
      :columns="columns"
      :queryParams="queryParams"
      :date-range="true" >
    </common-table>
  </page-content>
</template>
<script>

  export default {
    props:["iotId"],
    data () {
      return {
        queryParams:{
          iotId:'',
        },
      }
    },
    mounted () {
      // this.queryParams.iotId = this.iotId
      this.getList()
    },

    computed:{
      columns() {
        return [
          {
            title: '设备',
            dataIndex: 'deviceName',
          },
          {
            title: '设备编号',
            dataIndex: 'deviceNo',
          },
          {
            title: '消息内容',
            dataIndex: 'content',
            ellipsis: true,
          },
          {
            title: '时间',
            dataIndex: 'createdAt',
            width: 200
          }
        ]
      },
    },
    methods:{
      getList () {
        this.$refs.table.getData()
      },
    }
  }
</script>

<style>

</style>
